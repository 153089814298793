import React, { PureComponent, Fragment } from 'react';
import Loader from '../Loader';
import styles from "./style.module.css";
import { RLogo } from '../svg/raivenIcon'

const isBrowser = typeof window !== 'undefined' && window.env;
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY;
const eqlSsoUrl = isBrowser ? window.env.eqlSsoUrl : process.env.RAZZLE_RUNTIME_EQL_SSO_URL;
const graingerRootUrl = isBrowser ? window.env.graingerRootUrl : process.env.RAZZLE_RUNTIME_GR_URL;
const punchInRedirect = isBrowser ? window.env.punchInRedirect : process.env.RAZZLE_RUNTIME_PUNCHIN_REDIRECT;

export class SsoIframe extends PureComponent {
    constructor(props) {
        super(props);

        const supplierId = this.getURIQueryValue('supplier') || '';
        const searchTerm = this.getURIQueryValue('searchTerm') || '';
        const productId = this.getURIQueryValue('productId') || '';
        const accountNum = this.getURIQueryValue('accountNumber') || '';

        this.state = {
            ssoLoaded: false,
            supplierId,
            searchTerm,
            productId,
            accountNum,
            hasExtension: props.hasExtension,
            forwardUrl: props.forwardUrl,
            poForward: props.poForward,
            newTab: props.newTab,
            error: null
        };
    }

    async componentDidMount() {
        if (this.props.eql) {
            console.log('SSO :: detected EQL :: sso then forward to :: ', this.state.forwardUrl);
            let bearerToken = localStorage.getItem('token');
            let userData = JSON.parse(window.localStorage.getItem('userData'));
            if (!userData || !bearerToken) {
                setTimeout(() => {
                    bearerToken = localStorage.getItem('token');
                    userData = JSON.parse(window.localStorage.getItem('userData'));
                    const corpId = userData.Network[userData.activeNetworkIndex].Id;
                    const ssoUrl = `${eqlSsoUrl}?access_id=${bearerToken}&corpid=${corpId}`;
                    this.setState({ ssoUrl });
                }, 300);
            } else {
                const corpId = userData.Network[userData.activeNetworkIndex].Id;
                const ssoUrl = `${eqlSsoUrl}?access_id=${bearerToken}&corpid=${corpId}`;
                this.setState({ ssoUrl });
            }
            
        } else {
            const punchOutUrl = await this.getPunchOutURL();
           if(punchOutUrl){
            if (this.state.poForward) {
                const poForwardUrl = this.getPOForwardUrl( punchOutUrl.URL);
                this.setState({ forwardUrl: poForwardUrl, ssoUrl:  punchOutUrl.URL });
            } else {
                this.setState({ ssoUrl: punchOutUrl.URL, ssoLoaded: true });
            }

           }
           
        }
    }

    getURIQueryValue(key) {
        const qString = window.location.search;

        if (qString.includes(key)) {
            return qString.split(`${key}=`)[1].split('&')[0];
        }
        return undefined;
    }

    async getPunchOutURL() {
        const userData = JSON.parse(window.localStorage.getItem('userData'));
        const userToken = userData.Network[userData.activeNetworkIndex].UserToken;
        const url = `${apiRoot}/v1/Procurement/Supplier/PunchOutUrl?userToken=${userToken}&supplierId=${this.state.supplierId}&accountNumber=${this.state.accountNum}&redirectUrl=${window.location.origin}/${punchInRedirect}`;
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${apiKey}`,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        };
        try{
            const response = await this.fetchData(url, options);
            return response;
        }
        catch{
            this.setState({ error: 'Failed to fetch punchout url.' });
        }
    }

    getPOForwardUrl(punchOutUrl) {
        const { supplierId, searchTerm, productId } = this.state;
        let supplierUrlList = [
            {
                searchUrl: `${graingerRootUrl}/search?searchBar=true&searchQuery=${searchTerm}`,
                productUrl: `${graingerRootUrl}/product/${productId}`,
                name: 'Grainger',
                id: 2,
                notProductPage: false,
            },
            {
                searchUrl: `https://hdsupplysolutions.com/SearchDisplay?sType=SimpleSearch&searchTerm=${searchTerm}`,
                productUrl: `https://hdsupplysolutions.com/ProductDisplay?partNumber=${productId}`,
                name: 'HD Supply',
                id: 31,
                notProductPage: false,
            },
            {
                searchUrl: punchOutUrl.replace('billboard/billboardMessages', 'catalog/search') + '?Ntt=' + searchTerm,
                productUrl: punchOutUrl.replace('billboard/billboardMessages', 'catalog/catalogSku') + `?id=${productId}`,
                name: 'Office Depot',
                id: 1,
                notProductPage: false,
            },
        ];
        const matchedSupplier = supplierUrlList.find(supplier => {
            return supplier.id === supplierId;
        });
        const url = searchTerm.length > 0 ? matchedSupplier.searchUrl : matchedSupplier.productUrl;
        return url;
    }

    async fetchData(searchUrl, searchOptions = {}, onErrorLocation) {
        try {
            const response = await fetch(searchUrl, searchOptions);
            if (!response.ok) {
                console.log('fetchData :: FETCH :: NOT OK :: ', response);
                throw { ErrorCode: response.status, response };
            }
            return response.json();
        } catch (error) {
            console.log('fetchData :: FETCH :: ERROR :: ', error);
            throw { ErrorCode: 500, ...error };
        }
    }

    iFrameLoaded = () => {
        this.setState({ ssoLoaded: true });
    };

    render() {
        if (this.state.error) {
            return <div className={styles.errorContainer}>
                    <div  className={styles.errorContent}>
                        <div className={styles.raivenLogo}>
                            <RLogo/>
                        </div>
                        <div  className={styles.textContent}>
                            <div>
                                Sorry,<br/>this supplier is currently unavailable.
                            </div>
                            <div className={styles.detailsContainer}> 
                                Please try again later. We’re working on getting this fixed. If you have any questions please contact Raiven support at <a href="mailto:marketplace@raiven.com" className='email'>marketplace@raiven.com</a>.
                            </div>
                            <a href="/marketplace" className={styles.goBackLink}>
                                Go back to Raiven
                            </a>
                        </div>
                    </div>
                </div>
        }
        if (this.props.fullScreen && this.state.ssoLoaded) {
            const newUrl = this.state.forwardUrl || this.state.ssoUrl;
            console.log(
                'SsoIframe :: SSO Loaded, forwarding :: forwardUrl, ssoUrl',
                this.state.forwardUrl,
                this.state.ssoUrl,
            );
            if (this.state.newTab) {
                window.open(newUrl, '_blank');
                setTimeout(() => {
                    window.location.href = '/marketplace';
                }, 300);
            } else {
                if (newUrl.includes('officedepot') || newUrl.includes('benfieldelectric')) {
                    console.log('SSO :: Punchout :: Office Depot OR benfieldelectric');
                    // window.location.replace(newUrl + '?auroraResize=true')
                } else {
                    window.location.replace(newUrl);
                }
            }
        }
        // if(this.state.ssoUrl && this.state.hasExtension && !this.state.forwardUrl && !this.state.newTab) {
        //     console.log('SsoIframe :: PO in main window', this.state.forwardUrl, this.state.ssoUrl)
        //     window.location.replace(this.state.ssoUrl)
        // }
        if (document.querySelector('form') && document.querySelector('form').action.includes('/proqure/session')) {
            window.location.reload();
        }
        // if(this.state.ssoUrl && this.state.ssoLoaded && !this.state.forwardUrl) window.location.href = this.state.ssoUrl

        return (
            <Fragment>
                {this.state.ssoUrl && (
                    <Fragment>
                        <iframe
                            name='eqlFrame'
                            id='eqlFrame'
                            title='SSO Iframe'
                            className='flex-column'
                            key={new Date().getMilliseconds()}
                            src={
                                this.state.ssoLoaded && this.state.forwardUrl && this.state.forwardUrl.length > 0
                                    ? this.state.forwardUrl
                                    : this.state.ssoUrl
                            }
                            onLoad={this.iFrameLoaded}
                            overflow='hidden'
                            width='100%'
                            height={window ? window.innerHeight - 52 + 'px' : '400px'}
                            frameBorder='0'
                            allowFullScreen
                            style={{ marginTop: this.props.eql ? '48px' : '', marginBottom: '0' }}
                        />
                    </Fragment>
                )}
                {!this.state.ssoLoaded && (
                    <div className='overlay-loader'>
                        <Loader />
                    </div>
                )}
            </Fragment>
        );
    }
}

export default SsoIframe;
